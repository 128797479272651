import { Routes, Route, Navigate } from 'react-router-dom'

import Login from './pages/Login'
import TwoFa from './pages/TwoFactor'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import CreatePassword from './pages/CreatePassword'
import Missing from './pages/Missing'
import Layout from './components/Layout'
import AuthLayout from './layouts/AuthLayout'
import OhLayout from './layouts/OhLayout'
import RequireAuth from './components/RequireAuth'
import PersistLogin from './components/PersistLogin'
import CheckLogin from './components/CheckLogin'
import Dashboard from './pages/Admin/Dashboard'
import OhDashboard from './pages/Admin/OH/Dashboard'
import AccountSelect from './pages/AccountSelect'
import AccountSettings from './pages/Admin/AccountSettings'
import { ThemeProvider } from './context/ThemeProvider'
import { useEffect } from 'react'

import Clarity from '@microsoft/clarity'

function App() {
  const projectId = 'ogftd8bkpx'
  Clarity.init(projectId)
  return (
    <ThemeProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* Public Routes */}
          <Route element={<PersistLogin />}>
            {/* Routes for logged-in users */}
            <Route element={<RequireAuth />}>
              <Route element={<AuthLayout />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="account-select" element={<AccountSelect />} />
                <Route
                  path="/users/:id/account-settings"
                  element={<AccountSettings />}
                />
              </Route>
              <Route element={<OhLayout />}>
                <Route path="oh/dashboard" element={<OhDashboard />} />
              </Route>
            </Route>

            {/* Routes for logged-out users */}
            <Route element={<CheckLogin />}>
              <Route element={<AuthLayout />}>
                <Route path="/" element={<Login />} />
                <Route path="login" element={<Navigate to="/" replace />} />
                <Route path="2fa" element={<TwoFa />} />
                <Route path="password/forgot" element={<ForgotPassword />} />
                <Route path="password/reset" element={<ResetPassword />} />
                <Route
                  path="password/create/:token"
                  element={<CreatePassword />}
                />
              </Route>
            </Route>
            {/* Catch All */}
            <Route path="*" element={<Missing />} />
          </Route>
        </Route>
      </Routes>
    </ThemeProvider>
  )
}

export default App
