import React, { useRef, useState, useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import axios from '../api/axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faCheck,
  faChevronRight,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import { useTheme } from '../context/ThemeProvider'

const ACCOUNT_URL = 'v2/staff-hub/authenticate'
const DASHBOARD_URL = '/staff-hub/policy'

const Login = () => {
  const { setAuth, setShowLoader } = useAuth()
  const theme = useTheme()
  const style = {
    backgroundColor: theme.backgroundColor,
    color: theme.textColor,
  }
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const emailRef = useRef()

  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')

  const [errMsg, setErrMsg] = useState('')
  const [succMsg, setSuccMsg] = useState('')

  useEffect(() => {
    localStorage.setItem('origin', window.location.origin)
    if (location?.state) {
      if (location?.state?.messageType === 'error') {
        setErrMsg(location.state.message)
      } else if (location?.state?.messageType === 'success') {
        setSuccMsg(location.state.message)
      }
    }
  }, [])

  const handleSubmit = async (e) => {
    setShowLoader(true)
    e.preventDefault()
    axios
      .post(ACCOUNT_URL, { email, password: pwd })
      .then(function (response) {
        setShowLoader(false)
        let token = response.data.result.token
        let user = response.data.result.user
        let schools = response.data.result.schools
        localStorage.setItem('token', token)
        setEmail('')
        setPwd('')
        if (schools.length === 1) {
          setAuth({ email, pwd, user, token, schools: schools })
          navigate('dashboard')
        } else {
          setAuth({ email, pwd, user, token, schools: schools })
          navigate('account-select')
        }
      })
      .catch((error) => {
        setShowLoader(false)
        console.error(error)
        if (!error?.response) {
          setErrMsg('No Server Response')
        } else if (error.response?.status === 400) {
          setErrMsg(
            'You do not have access to this application with your current policy.'
          )
        } else if (error.response?.status === 401) {
          setErrMsg('Incorrect Email or Password entered.')
        } else {
          setErrMsg('Error Occurred. Could not log you in.')
        }
      })
  }

  // const togglePersist = () => {
  //   setPersist((prev) => !prev)
  // }

  // useEffect(() => {
  //   localStorage.setItem('persist', persist)
  // }, [persist])

  return (
    <>
      {/* <div className="rounded-md bg-blue-50 p-4 max-w-4xl w-full mx-auto mb-10 -mt-20">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-blue-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-sky-700">
              Please Note: Our offices are closed from midday Tuesday 24th
              December and will re-open on Thursday 2nd January. Unfortunately,
              we're unable to take any telephone calls over this period, however
              you can still access your online portal and upload any
              documentation. On behalf of the whole team, however you're
              celebrating, have a wonderful time and enjoy the festivities.
              Here's to making 2025 even more magical!
            </p>
          </div>
        </div>
      </div> */}

      <h3 className="text-2xl sm:text-3xl md:text-5xl font-bold text-white mb-8 sm:mb-16">
        Log in to Your Account
      </h3>

      {errMsg && (
        <div className="rounded-md bg-red-50 p-4 mb-4">
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              aria-hidden="true"
              className="h-5 w-5"
              fill="#dc2626"
            >
              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
            </svg>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{errMsg}</h3>
            </div>
          </div>
        </div>
      )}

      {succMsg && (
        <div className="rounded-md bg-green-50 p-4 mb-4">
          <div className="flex">
            <div>
              <h3 className="text-md font-medium text-green-800">{succMsg}</h3>
            </div>
          </div>
        </div>
      )}

      <div className=" w-full bg-white shadow-lg rounded-md overflow-hidden max-w-4xl flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-7/12 p-6 lg:p-12">
          <form
            onSubmit={handleSubmit}
            method="POST"
            className="space-y-4 sm:space-y-6"
          >
            <img
              src={theme.logo.url}
              className="h-[60px]"
              alt={theme.logo.alt}
            />
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-slate-500"
              >
                Email address
              </label>
              <div className="">
                <input
                  id="email"
                  name="email"
                  type="email"
                  ref={emailRef}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="text-base appearance-none border rounded w-full py-2 px-3 text-gray-700 "
                />
              </div>
            </div>

            <div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-slate-500"
                >
                  Password
                </label>
                <div className="">
                  <input
                    id="password"
                    name="password"
                    onChange={(e) => setPwd(e.target.value)}
                    type="password"
                    required
                    className="text-base appearance-none border rounded w-full py-2 px-3 text-gray-700 "
                  />
                </div>
              </div>
              <div className="text-sm flex justify-end">
                <a
                  href="/password/forgot"
                  className="mt-1.5 text-xs"
                  style={{ color: style.color }}
                >
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="button"
                style={{
                  borderColor: !email || !pwd ? 'gray' : style.backgroundColor,
                  cursor: !email || !pwd ? 'not-allowed' : 'pointer',
                }}
              >
                <span
                  className=""
                  style={{ color: !email || !pwd ? 'gray' : style.color }}
                >
                  Log in
                </span>
                <span
                  className=""
                  style={{
                    backgroundColor:
                      !email || !pwd ? 'gray' : style.backgroundColor,
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            {/* Not a member?{' '} */}
            {/* <a href="#" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                Start a 14 day free trial
              </a> */}
          </p>
        </div>

        <div className="w-full lg:w-5/12 bg-gray-200/70 flex flex-col p-6 lg:p-10">
          <h2 className="text-slate-700 font-bold text-lg sm:text-xl lg:text-2xl leading-6 mb-4 sm:mb-6">
            {theme.login.title}
          </h2>
          <div className="flex">
            <ul className="space-y-2 sm:space-y-3 md:space-y-4">
              <li className="flex text-sm sm:text-base lg:text-lg sm:leading-5">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-emerald-500 mr-3 sm:mr-4 text-lg sm:text-xl lg:text-2xl "
                />
                {theme.login.first}
              </li>
              <li className="flex text-sm sm:text-base lg:text-lg sm:leading-5">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-emerald-500 mr-3 sm:mr-4 text-lg sm:text-xl lg:text-2xl "
                />
                {theme.login.second}
              </li>
              <li className="flex text-sm sm:text-base lg:text-lg sm:leading-5">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-emerald-500 mr-3 sm:mr-4 text-lg sm:text-xl lg:text-2xl "
                />
                {theme.login.third}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Contact />
    </>
  )
}

export default Login
